// libraries
import styled from 'styled-components';

// Colors
import colors from '../../../../styles/colors';

interface BackgroundRadiusInterface {
    backgroundColor: number,
}

// styles
export const TextProgressBar = styled.span`
    color: ${colors.basic.white};
    font-size: 20;
    margin-top: 12px;
`;

export const ContainerProgressBar = styled.div`
    width: 160px;
    height: 160px;
`;

export const TextDropzone = styled.text`
    color: ${colors.basic.white};
    font-size: 24;
`;

export const ContainerDropzone = styled.div`
    background-color: ${colors.basic.lightGrey}CC;
    display: flex;
    flex: 1;
    height: 300px;
    margin-bottom: 20px;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    border-style: dashed;
`;

export const ModalContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
`;

export const Header = styled.div`
    width: 100%;
`;

export const Footer = styled.div`
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    /* margin-top: 100px; */
    pointer-events: auto;
`;

export const ContainerHome = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
`;

export const ButtonExit = styled.div`
    width: 150px;
    height: 40px;
    background-color: ${colors.basic.white};
    border: 1px solid ${colors.basic.darkblue};
    border-radius: 20px;
    color: ${colors.basic.darkblue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
`;

export const Container = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    margin-bottom: 20px;
    max-height: calc(110vh - 220px);
    width: 90%;
    max-width: 1200px;
    overflow-y: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const IconBlue = styled.img`
    align-items: center;
    justify-content: center;
    width: 30px;

`;

export const ContainerHeader = styled.div`
    width: 23%;
    padding: 18px;
`;

export const InputNullSelectedArchive = styled.input`
    align-items: center;
    cursor: pointer;
    height: 40px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

export const TextNewCollaborator = styled.h4`
    color: ${colors.basic.darkblue};
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

export const Logo = styled.img`
    height: 120px;
    width: 120px;
`;

export const ContainerMainCategories = styled.div`
    /* align-items: center; */
    /* background-color: pink; */
    /* justify-content: space-between; */
    /* display: flex; */
    border: 1px solid ${colors.basic.blue};
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 20px;
    width: 100%;
`;

export const IconChecked = styled.img`
    height: 20px;
    margin-right: 10px;
    width: 20px;
`;

export const IconArrowCategories = styled.img`
    height: 30px;
    width: 30px;
`;

export const ContainerCicleChecked = styled.div`
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.basic.blue};
    border-radius: 10px;
    margin-right: 10px;
`;

export const ContainerRadius = styled.div<BackgroundRadiusInterface>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor > 0 ? colors.basic.darkblue : colors.basic.blue)};
    justify-content: center;
    border-radius: 8px;
    display: flex;
    height: 16px;
    width: 16px;
`;

export const TxtCategoriesSelected = styled.text`
    color: ${colors.basic.darkblue};
    font-weight: 500;
`;

export const ContainerCategoriesSelected = styled.div`
    align-items: center;
    justify-content: space-between;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
    height: 30px;
`;

export const TextFinish = styled.text`
    background-color: ${colors.basic.darkblue};
    color: ${colors.basic.white};
    border-radius: 3px;
    padding: 2px 2px 2px 2px;
    font-size: 13px;
    cursor: pointer;
`;

export const IconArrowCategoriesGoBack = styled.img`
    cursor: pointer;
    transform: rotate(180deg);
    height: 30px;
    width: 30px;
`;

export const DivLine = styled.div`
    background-color: #74F4D6;
    min-width: 100%;
    height: 8px;
    margin-top: 10px;
`;

export const TextInput = styled.h4`
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 18px;
    color: ${colors.basic.darkblue};
`;

export const IconDownload = styled.img`
    height: 24px;
    margin-left: 5px;
    width: 24px;
`;

export const InputText = styled.input`
    display: inline;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.basic.blue};
    border-radius: 5px;
    color: ${colors.basic.darkblue};
    font-size: 14px;
    padding-left: 10px;

    /* styles placeholder */
    ::-webkit-input-placeholder {
        color: ${colors.basic.blue};
        font-size: 14px;
    }
`;

export const ButtonSave = styled.div`
    min-width: 150px;
    padding: 0px 16px;
    height: 40px;
    background-color: ${colors.basic.orange};
    border-radius: 20px;
    color: ${colors.basic.white};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ContainerInput = styled.div`
    display: inline-block;
    justify-content: center;
`;
export const DivInputText = styled.div`
    display: flex;
    width: 50%;
`;

export const Body = styled.div`
    float: left;
    width: 40%;
    max-width: 40%;
    margin-left: 5%;
`;

export const ContainerNewCollaborator = styled.div`
    display: flex;
    width: 220px;
    padding-left: 8px;
`;

export const ContainerManage = styled.div`
    float: left;
    width: 40%;
    margin-left: 5%;
`;
export const ContainerRadio = styled.input`
    width: 20px;
    height: 20px;
`;
export const TextRadio = styled.text`
    color: ${colors.basic.darkerblue};
    margin-left: 10px;
`;
export const ContainerMultipleChoice = styled.div`
    width: 14%;
    display: flex;
    margin-left: 77%;
    margin-bottom: 10px;
`;
export const ContainerSelect = styled.select`
    display: inline;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.basic.blue};
    border-radius: 5px;
    color: ${colors.basic.darkblue};
    font-size: 14px;
    padding-left: 10px;

    /* styles placeholder */
    ::-webkit-input-placeholder {
        color: ${colors.basic.blue};
        font-size: 14px;
    }
`;
export const ContainerOption = styled.option``;

export const TextArchive = styled.text`
    color: ${colors.basic.darkblue};
    text-transform: capitalize;
    font-weight: 400;
    /* -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; */
`;

export const ContainerArchive = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
`;

export const ContainerTest = styled.div`
    border: 1px solid ${colors.basic.blue};
    border-radius: 5px;
    height: 40px;
    padding-top: 2px;
    width: 100%;
    max-width: 100%;
    display: inline;
`;

export const ContainerAdjustSelects = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
`;

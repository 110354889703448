/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { lightFormat } from 'date-fns';
import axios from 'axios';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalManagePage from './components/ModalManage/ModalManagePage';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import iconCorrectError from '../../assets/images/iconCorrectError.png';
import iconDescutive from '../../assets/images/iconDescutive.png';
import iconObjective from '../../assets/images/iconObjective.png';
import iconGenderBlue from '../../assets/images/iconGenderBlue.png';

// js
import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    LineTop,
    ContainerAcess,
    ContainerUserAll,
    ContainerInputUser,
    IconUserTeacher,
    TextTeacherActive,
    TextNumberActive,
    TextDefault,
    DivLine,
    IconTrash,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './issuaManageStyles';
import questions from '../../services/questions';
import categories from '../../services/categories';
import book from '../../services/book';
import colors from '../../styles/colors';
import sendSearch from '../../assets/images/sendSearch.png';
import imageToEdit from '../../assets/images/imageToEdit.png';
import iconTrashRed from '../../assets/images/iconTrashRed.png';
import constants from '../../configs/constants';

// contexts
import ModalDeleteAllQuestionsPage from './components/ModalDeleteAllQuestions/ModalDeleteAllQuestionsPage';

const issueManagePage: React.FC = () => {
    // constants
    const baseURL = constants.baseUrl;
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;
    const configs = {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': GetToken || '',
        },
    };

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState<any>();
    const [open, setOpen] = useState(false);
    const [allCategories, setAllCategories] = useState([]);
    const [allBooks, setAllBooks] = useState([]);
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [userEdit, setUserEdit] = useState<any>();
    const [typeQuestion, setTypeQuestion] = useState(['multiple_choices', 'discursive', 'right_or_wrong']);
    const [allQuestionsObjective, setAllQuestionsObjective] = useState('');
    const [allQuestionsDiscursive, setAllQuestionsDiscursive] = useState('');
    const [allQuestionsRightOrWrong, setAllQuestionsRightOrWrong] = useState('');
    const [disabledNewQuestions, setDisabledNewQuestions] = useState(true);
    const [openDelete, setOpenDelete] = useState(false);

    // methods
    const GetAllCategories = async () => {
        const GetCategories = await categories.GetAllCategories('', 0, 0, GetToken);
        const RemoveOneCategories = GetCategories.result.filter((v: any) => v.id !== 3);
        setAllCategories(RemoveOneCategories);
    };

    const GetAllBooks = async () => {
        const GetBook = await book.GetAllBook('', 0, 0, GetToken);
        setAllBooks(GetBook.result);
    };

    async function BulkUpload() {
        const response = await axios.get(`${baseURL}/questions/bulkProgress`, configs);
        if (response.data?.result) {
            const disabledQuestion = response.data.result?.progress >= 0 && response.data.result?.progress < 100;
            setDisabledNewQuestions(disabledQuestion);
            if (disabledQuestion) {
                setOpen(false);
            }
        } else {
            setDisabledNewQuestions(false);
        }
    }

    const handleOpen = async (infoUser?: string) => {
        setOpen(true);
        if (infoUser) {
            setUserEdit(infoUser);
        } else {
            setUserEdit(null);
            await BulkUpload();
        }
    };

    async function RenderQuantityPagination(searchSelected: string, typeQuestionSelect: Array<string>) {
        const allQuestionsNotPage = await questions.GetAllQuestions(searchSelected || '', typeQuestionSelect || [], 0, 0, GetToken);
        const formatPagination = String(allQuestionsNotPage.result?.qtd / 20).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function ActionInitial() {
        const allQuestions = await questions.GetAllQuestions('', [], 1, limit, GetToken);
        const allQuestionsNotPage = await questions.GetAllQuestions('', [], 0, 0, GetToken);
        setAllQuestionsObjective(allQuestionsNotPage?.result?.qtdMultipleChoices);
        setAllQuestionsDiscursive(allQuestionsNotPage?.result?.qtdDiscursive);
        setAllQuestionsRightOrWrong(allQuestionsNotPage?.result?.qtdRightOrWrong);
        setDataFinal(allQuestions.result?.list);
        RenderQuantityPagination(search, []);
    }

    async function ActionSearch() {
        const validationType = typeQuestion?.length === 3 ? [] : typeQuestion;
        const allQuestions = await questions.GetAllQuestions(search, validationType, 1, limit, GetToken);
        setDataFinal(allQuestions.result?.list);
        RenderQuantityPagination(search, validationType);
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        if (activeRefresh === true) {
            ActionInitial();
        }
    };

    function renderUser(img: string, title: string, subtitle: string, index: number) {
        const TradeInfo = async () => {
            if (index === 1) {
                if (typeQuestion.includes('multiple_choices') && typeQuestion.length !== 1) {
                    const removeItem = typeQuestion.filter((v) => v !== 'multiple_choices');
                    setTypeQuestion(removeItem);
                    const allManagers = await questions.GetAllQuestions(search, removeItem, 1, limit, GetToken);
                    setDataFinal(allManagers.result?.list);
                    RenderQuantityPagination(search, removeItem);
                } else if (typeQuestion.includes('multiple_choices') && typeQuestion.length === 1) {
                    setTypeQuestion(['discursive', 'multiple_choices', 'right_or_wrong']);
                    const allManagers = await questions.GetAllQuestions(search, ['discursive', 'multiple_choices', 'right_or_wrong'], 1, limit, GetToken);
                    setDataFinal(allManagers.result?.list);
                    RenderQuantityPagination(search, ['discursive', 'multiple_choices', 'right_or_wrong']);
                } else {
                    setTypeQuestion(['multiple_choices', ...typeQuestion]);
                    const validationTypeUser = ['multiple_choices', ...typeQuestion]?.length === 3 ? [] : ['multiple_choices', ...typeQuestion];
                    const allManagers = await questions.GetAllQuestions(search, validationTypeUser, 1, limit, GetToken);
                    setDataFinal(allManagers.result?.list);
                    RenderQuantityPagination(search, validationTypeUser);
                }
            } else if (index === 2) {
                if (typeQuestion.includes('discursive') && typeQuestion.length !== 1) {
                    const removeItem = typeQuestion.filter((v) => v !== 'discursive');
                    setTypeQuestion(removeItem);
                    const allManagers = await questions.GetAllQuestions(search, removeItem, 1, limit, GetToken);
                    setDataFinal(allManagers.result?.list);
                    RenderQuantityPagination(search, removeItem);
                } else if (typeQuestion.includes('discursive') && typeQuestion.length === 1) {
                    setTypeQuestion(['discursive', 'multiple_choices', 'right_or_wrong']);
                    const allManagers = await questions.GetAllQuestions(search, ['discursive', 'multiple_choices', 'right_or_wrong'], 1, limit, GetToken);
                    setDataFinal(allManagers.result?.list);
                    RenderQuantityPagination(search, ['discursive', 'multiple_choices', 'right_or_wrong']);
                } else {
                    setTypeQuestion([...typeQuestion, 'discursive']);
                    const validationTypeUser = [...typeQuestion, 'discursive']?.length === 3 ? [] : [...typeQuestion, 'discursive'];
                    const allManagers = await questions.GetAllQuestions(search, validationTypeUser, 1, limit, GetToken);
                    setDataFinal(allManagers.result?.list);
                    RenderQuantityPagination(search, validationTypeUser);
                }
            } else if (index === 3) {
                if (typeQuestion.includes('right_or_wrong') && typeQuestion.length !== 1) {
                    const removeItem = typeQuestion.filter((v) => v !== 'right_or_wrong');
                    setTypeQuestion(removeItem);
                    const allManagers = await questions.GetAllQuestions(search, removeItem, 1, limit, GetToken);
                    setDataFinal(allManagers.result?.list);
                    RenderQuantityPagination(search, removeItem);
                } else if (typeQuestion.includes('right_or_wrong') && typeQuestion.length === 1) {
                    setTypeQuestion(['discursive', 'multiple_choices', 'right_or_wrong']);
                    const allManagers = await questions.GetAllQuestions(search, ['discursive', 'multiple_choices', 'right_or_wrong'], 1, limit, GetToken);
                    setDataFinal(allManagers.result?.list);
                    RenderQuantityPagination(search, ['discursive', 'multiple_choices', 'right_or_wrong']);
                } else {
                    setTypeQuestion([...typeQuestion, 'right_or_wrong']);
                    const validationTypeUser = [...typeQuestion, 'right_or_wrong']?.length === 3 ? [] : [...typeQuestion, 'right_or_wrong'];
                    const allManagers = await questions.GetAllQuestions(search, validationTypeUser, 1, limit, GetToken);
                    setDataFinal(allManagers.result?.list);
                    RenderQuantityPagination(search, validationTypeUser);
                }
            }
        };

        const ValidationFocus = () => {
            if (index === 1) {
                return 'multiple_choices';
            }
            if (index === 2) {
                return 'discursive';
            }

            return 'right_or_wrong';
        };

        return (
            <ContainerUserAll>
                <ContainerInputUser onClick={TradeInfo}>
                    <IconUserTeacher src={img} />
                    <TextTeacherActive>{title}</TextTeacherActive>
                    <TextNumberActive>
                        <TextDefault>{subtitle}</TextDefault>
                    </TextNumberActive>
                </ContainerInputUser>
                {typeQuestion.includes(ValidationFocus()) ? <DivLine /> : null}
            </ContainerUserAll>
        );
    }

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteQuestion') {
                await questions.DeleteOneQuestion(item?.id, GetToken);
                ActionInitial();
            }
            if (valueSelected === 'EditQuestion') {
                const GetOneQuestions = await questions.GetAllQuestions('', [], 0, 0, GetToken, item?.id);
                if (GetOneQuestions?.status === 200) {
                    handleOpen(GetOneQuestions?.result?.list[0]);
                }
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>Título:</TextThView>
                    {item?.title || ''}
                </TextTd>
                <TextTd>
                    <TextThView>Matérias:</TextThView>
                    {item?.matter}
                </TextTd>
                <TextTd>
                    <TextThView>Autor:</TextThView>
                    {item?.author}
                </TextTd>
                <TextTd>
                    <TextThView>Likes:</TextThView>
                    {item?.amount_like || 0}
                </TextTd>
                <TextTd>
                    <TextThView>Deslikes:</TextThView>
                    {item?.amount_deslike || 0}
                </TextTd>
                <TextTd>
                    <TextThView>Criado em:</TextThView>
                    {lightFormat(new Date(item?.created_at || '2021-07-26T19:40:54.919Z'), 'dd/MM/yyyy') || ''}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditQuestion">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar questão</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteQuestion">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir questão</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function onChangePagination(event: any, pageSelected: number) {
        // const allQuestions = await questions.GetAllQuestions(search, pageSelected, limit, GetToken);
        // setDataFinal(allQuestions.result);
        // setPageActual(pageSelected);
        const allQuestions = await questions.GetAllQuestions(search, typeQuestion?.length === 3 ? [] : typeQuestion, pageSelected, limit, GetToken);
        setDataFinal(allQuestions.result?.list);
        setPageActual(pageSelected);
    }

    function handleClosedDelete() {
        setOpenDelete(!openDelete);
    }

    async function onDelete() {
        const ActionDelete = await questions.DeleteAllQuestions(GetToken);
        setOpenDelete(false);
        // eslint-disable-next-line no-alert
        alert(ActionDelete?.message);
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
        GetAllCategories();
        GetAllBooks();
        BulkUpload();
    }, []);

    return (
        <Container>
            <Header>
                <HeaderComponent iconPage={iconGenderBlue} text="GERENCIAR QUESTÕES" />
                <LineTop />
                <ContainerAcess>
                    {renderUser(iconObjective, 'Questões Objetiva', allQuestionsObjective, 1)}
                    {renderUser(iconDescutive, 'Questões Discursiva', allQuestionsDiscursive, 2)}
                    {renderUser(iconCorrectError, 'Questões Certo-Errado', allQuestionsRightOrWrong, 3)}
                </ContainerAcess>
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título, enunciado ou resolução..."
                        onChange={changeInputShare}
                        value={search}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <IconTrash src={iconTrashRed} onClick={handleClosedDelete} />
                {disabledNewQuestions ? null : (
                    <ContainerButtonAdd>
                        <RiMenuAddFill color={colors.basic.white} />
                        <ButoonToAdd onClick={() => handleOpen()}>Adicionar Nova Questão</ButoonToAdd>
                    </ContainerButtonAdd>
                )}
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>TÍTULO</TextTh>
                            <TextTh>MATÉRIAS</TextTh>
                            <TextTh>AUTOR</TextTh>
                            <TextTh>QTD. LIKES</TextTh>
                            <TextTh>QTD. DESLIKES</TextTh>
                            <TextTh>CRIADO EM</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalManagePage(open, handleClose, userEdit, allCategories, allBooks)}
            {ModalDeleteAllQuestionsPage(openDelete, handleClosedDelete, onDelete)}
        </Container>
    );
};

export default issueManagePage;
